import React from 'react'
import Layout from '../components/layout'

import './__styles__/404.module.scss'

const NotFoundPage = () => (
  <Layout>
    <div styleName="container">
      <div styleName="inner">
        <h2>404</h2>
        <h4>Page not found</h4>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
